<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong>Hospitals / Eye Donation Centers</strong></span>
      <v-btn color="primary" class="mr-0" @click="addUserModal = true"> Add Hospital</v-btn>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.hospital_name" label="Hospital Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.phone_number" label="Phone Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field v-model="filter.district" label="District" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="HospitalSearchList()"> Search</v-btn>
        <v-btn color="primary" @click="getHospitalList()">Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">
       
       
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :items="hospitalList" :items-per-page="10" class="table-rounded" fixed-header
      height="400">
      <template #[`item.operate`]="{ item }">
        <v-btn color="primary" class="mr-3" @click="editHospitalform(item)"> Edit </v-btn>
        <EditAddHospital :visible="editAddHospital" :item="editItem" @close="editAddHospital = false" />
        <v-btn color="error" text @click="deleteHospitals(item)"> Delete </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="deleteHospital(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addUserModal" max-width="1000">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Add Hospital</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addHospitalList.hospital_name" label="Hospital Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addHospitalList.address" label="Address*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addHospitalList.phone_number" label="Phone No*" required counter="10"
                  @keypress="onlyNumber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete v-model="addHospitalList.hospital_type" label="Type*" :items="Type" @change="sss()">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="addHospitalList.hospital_type == 'HCRP Hospital'">
                <v-text-field v-model="addHospitalList.contact_name" label="Contact Name*"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" v-if="addHospitalList.hospital_type == 'Transplantation Hospital'">
                <v-autocomplete v-model="addHospitalList.hospital_category" label="Hospital Category*" :items="Category"
                  @change="ss()"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="addHospitalList.hospital_type == 'Transplantation Hospital'">
                <v-text-field v-model="addHospitalList.surgeon_name" label="Surgeon Name*"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" v-if="addHospitalList.hospital_category === 'CDS'">
                <v-autocomplete v-model="addHospitalList.state" label="State*" required :items="state2"
                  item-text="state_name" @change="dateItem()"></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" v-if="addHospitalList.hospital_category != 'CDS'">
                <v-autocomplete v-model="addHospitalList.district_name" label="District*" required @change="dataItem1()"
                  :items="districtList" item-text="district_title"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" v-if="addHospitalList.hospital_category == 'CDS'">
                <v-autocomplete v-model="addHospitalList.district_name" label="District*" required @change="dataItem2()"
                  :items="districtList2" item-text="district_title"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete v-model="addHospitalList.city" label="City*" required :items="roleList"
                  item-text="name"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addHospitalList.email" label="Email*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="addHospitalList.area" label="Area*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-datetime-picker v-model="HOTAvalidity" time-format="HH:mm:ss" label="HOTA Validity*" required>
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-file-input v-model="file" chips accept="image/*" label="Certificate*" @change="onFileChange" />
              </v-col>
              <v-col cols="2" md="2">
                <img v-img style="width: 120%" :src="url2" />
              </v-col>
            </v-row>
          </v-container>
          <small class="ml-3">*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="Button1()"> Cancel </v-btn>
          <v-btn color="primary" @click="addHospital()" :disabled="!formIsValid"> Save </v-btn>
          <!-- <v-btn color="darken-1" text @click="adduserModel =false">edit</v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import VerticalNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import hospitalService from '@/service/EyeDonationCenter.service'
import axios from 'axios'
import moment from 'moment'
import EditAddHospital from '../Hospitals/EditHospital.vue'
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const baseUrl = 'https://api.stage.ebsr.in/users/'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token

export default {
  components: { VerticalNavMenu, EditAddHospital },

  data() {
    return {
      citysearch: [],
      Category1: '',
      file: null,
      image: null,
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      editItem: '',
      timeout: 2000,
      DeleteModal: false,
      thisItemId: '',
      headers: [
        { text: 'Hospital Id', value: 'id', align: ' d-none' },
        { text: 'Hospital Name', value: 'hospital_name', width: '150px' },
        { text: 'Address', value: 'address', width: '150px' },
        { text: 'Phone No', value: 'phone_number', width: '150px' },
        { text: 'Hospital Type', value: 'hospital_type', width: '150px' },
        { text: 'City', value: 'city', width: '150px' },
        { text: 'District', value: 'district', width: '100px' },
        { text: 'Email', value: 'email', width: '150px' },
        { text: 'Area', value: 'area', width: '100px' },
        // { text: 'Contact Name', value: 'contact_name', width: '150px' },
        { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
      hospitalList: [],
      Type: ['HCRP Hospital', 'Transplantation Hospital'],
      Category: ['Government Hospital', 'Private Hospital', 'CDS'],
      roleList: [{ city_id: '', name: '' }],
      districtList: [{ districtid: '', district_title: '' }],
      districtList2: [{ districtid: '', district_title: '' }],
      state2: [{ state_name: '', state_id: '' }],
      state1: [],

      filter: {
        hospital_name: '',
        phone_number: '',
        district: '',
      },
      addHospitalList: {
        hospital_name: '',
        address: '',
        phone_number: '',
        city: '',
        district_name: '',
        email: '',
        area: '',
        contact_name: '',
        hospital_type: '',
        surgeon_name: '',
        HOTA_validity: '',
        hospital_category: '',
        upload_certificate: '',
        state: '',
      },
      addUserModal: false,
      editAddHospital: false,
    }
  },

  watch: {
    options: {
      handler() {
        this.getHospitalList()
        this.GetCity()
        this.GetDistrictList1()
      },
      deep: true,
    },
  },

  computed: {
    formIsValid() {
      return (
        this.addHospitalList.hospital_name &&
        this.addHospitalList.email.match(validRegex) &&
        this.addHospitalList.phone_number &&
        this.addHospitalList.address &&
        this.addHospitalList.city &&
        // this.addHospitalList.district_name &&
        this.addHospitalList.area &&
        this.HOTAvalidity &&
        this.file
      )
    },

    HOTAvalidity: {
      get() {
        return this.addHospitalList.HOTA_validity
          ? moment(this.addHospitalList.HOTA_validity).format('YYYY-MM-DD HH:mm:ss')
          : ''
      },
      set(value) {
        this.$set(
          this.addHospitalList,
          'HOTA_validity',
          moment(value).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss'),
        )
      },
    },
    url2() {
      if (!this.file) return
      return URL.createObjectURL(this.file)
    },
  },

  async mounted() {
    this.getHospitalList()
    this.GetCity()
    this.GetDistrictList1()
    this.GetDistrictList()
    this.GetstatetList()
  },
  methods: {
    Button1() {
      // this.addHospitalList.hospital_name='',
      // this.addHospitalList.address='',
      // this.addHospitalList.phone_number='',
      // this.addHospitalList.city='',
      // this.addHospitalList.district_name='',
      // this.addHospitalList.email='',
      // this.addHospitalList.area='',
      // this.addHospitalList.contact_name='',
      // this.addHospitalList.hospital_type='',
      // this.addHospitalList.surgeon_name='',
      // // this.addHospitalList.HOTA_validity= moment('2022-01-01 22:22:22'),
      // this.addHospitalList.hospital_category='',
      // this.file=null,
      // this.HOTAvalidity= moment('2022-01-01 22:22:22'),

      this.addUserModal = false
      window.location.reload()
    },
    sss() {
      if (this.addHospitalList.hospital_type === 'HCRP Hospital') {
        this.OneCity()
        this.addHospitalList.hospital_category = ''
      } else {
        this.GetCity()
      }
    },
    ss() {
      if (this.addHospitalList.hospital_category != 'CDS') {
        this.addHospitalList.state = ''
        this.OneCity()
      } else {
        this.GetCity()
      }
    },

    dateItem() {
      const stateId = this.state1.find(o => o.state_name === this.addHospitalList.state)
      const valuee = stateId.state_id
      // console.log("state id", valuee)
      const CityId = this.districtList1.filter(e => e.state_id == valuee)
      // console.log("city name",CityId)
      this.districtList2 = CityId
    },
    dataItem2() {
      const district = this.districtList1.find(o => o.district_title === this.addHospitalList.district_name)
      const valuee = district.districtid
      // console.log("skeeeee", district)
      const cityIDD = this.citysearch.filter(e => e.district_id == valuee)
      // console.log("city", cityIDD)
      this.roleList = cityIDD
    },

    dataItem1() {
      const districtid = this.districtList.find(e => e.district_title === this.addHospitalList.district_name)
      const valuee = districtid.districtid
      // console.log("disdisdf",valuee)
      const cityIDD = this.citysearch.filter(e => e.district_id == valuee)
      //  console.log("hellooo",cityIDD)
      this.roleList = cityIDD
    },

    onFileChange() {
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = e => {
        this.addHospitalList.upload_certificate = e.target.result
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault()
      }
    },

    async GetCity() {
      const service = new hospitalService()
      let response = await service.CityList()
      if (response) {
        this.citysearch = response.data
        this.roleList = response.data
        // console.log("helllo",this.citysearch)
      } else {
        this.roleList = []
      }
    },
    async OneCity() {
      const service = new hospitalService()
      let response = await service.OneCityList()
      if (response) {
        // console.log("city list", response.data )
        this.citysearch = response.data
        this.roleList = response.data
      } else {
        this.roleList = []
      }
    },

    async GetDistrictList1() {
      const service = new hospitalService()
      let response = await service.DistrictList11()
      if (response) {
        this.districtList = response.data
        // console.log("districtis",this.districtList)
      } else {
        this.districtList = []
      }
    },

    async GetDistrictList() {
      const service = new hospitalService()
      let response = await service.DistrictList()
      if (response) {
        this.districtList1 = response.data
      } else {
        this.districtList1 = []
      }
    },
    async GetstatetList() {
      const service = new hospitalService()
      let response = await service.stateList()
      if (response) {
        this.state1 = response.data
        const Statedata = this.state1.filter(d => d.state_name != 'Rajasthan')
        this.state2 = Statedata
      } else {
        this.state1 = []
      }
    },

    editHospitalform(e) {
      this.editAddHospital = true
      this.editItem = e
    },

    deleteHospitals(e) {
      this.DeleteModal = true
      this.thisItemId = e.id
    },

    async getHospitalList() {
      this.listLoading = true
      const service = new hospitalService()
      let response = await service.hospitalList()
      if (response) {
        this.hospitalList = response.data
        this.totallist = this.hospitalList.length
        this.filter.hospital_name = ''
        this.filter.phone_number = ''
        this.filter.district = ''
      } else {
        this.hospitalList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async addHospital() {
      if (this.addHospitalList.phone_number.length < 10) {
        return false, (this.snackbarText = 'Phone number should be 10 character.'), (this.snackbar = true)
      }
      const data = {
        hospital_name: this.addHospitalList.hospital_name,
        address: this.addHospitalList.address,
        phone_number: this.addHospitalList.phone_number,
        hospital_type: this.addHospitalList.hospital_type,
        city_id: this.addHospitalList.city,
        district_id: this.addHospitalList.district_name,
        email: this.addHospitalList.email,
        area: this.addHospitalList.area,
        contact_name: this.addHospitalList.contact_name,
        surgeon_name: this.addHospitalList.surgeon_name,
        HOTA_validity: this.addHospitalList.HOTA_validity,
        upload_certificate: this.addHospitalList.upload_certificate,
        hospital_category: this.addHospitalList.hospital_category,
        state: this.addHospitalList.state,
      }
      try {
        const response = await axios.post(`${baseUrl}add_hospital`, data, {
          headers: {
            'Cantent-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == 200) {
          this.addUserModal = false
          this.snackbarText = response.data.message
          this.getHospitalList()
          this.addHospitalList.hospital_name = ''
          this.addHospitalList.address = ''
          this.addHospitalList.phone_number = ''
          this.addHospitalList.hospital_type = ''
          this.addHospitalList.city = ''
          this.addHospitalList.district_name = ''
          this.addHospitalList.email = ''
          this.addHospitalList.area = ''
            ; (this.addHospitalList.contact_name = ''),
              (this.addHospitalList.surgeon_name = ''),
              (this.file = null),
              (this.HOTAvalidity = null)
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async deleteHospital(item) {
      const data = {
        id: item,
      }
      try {
        const response = await axios.post(`${baseUrl}delete/hospital`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        console.log('response', response)
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getHospitalList()
          this.DeleteModal = false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async HospitalSearchList() {
      this.listLoading = true
      if (this.filter.hospital_name || this.filter.phone_number || this.filter.district) {
        const data = {
          hospital_name: this.filter.hospital_name,
          phone_number: this.filter.phone_number,
          district: this.filter.district,
        }
        try {
          const response = await axios.post(`${baseUrl}hospital/search`, data, {
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          if (response) {
            this.hospitalList = response.data.data
            this.totallist = this.hospitalList.length
          } else {
            this.hospitalList = []
            this.totallist = 0
          }
          this.listLoading = false
        } catch (e) {
          console.log(e)
        }
      } else {

        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
    },
  },
}
</script>
